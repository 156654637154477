import React, { useContext, useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import Toaster from 'components/commercetools-ui/atoms/toaster';
import 'tailwindcss/tailwind.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/app.css';
import { sdk } from 'sdk';
import { FrontasticProvider } from 'frontastic';
import { GTMscript } from 'frontastic/tastics/b2b/gtm';
import 'bootstrap/dist/css/bootstrap.css';
import Head from 'next/head';

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const router = useRouter();

  sdk.configureForNext(router.locale as string);
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    const random = Math.random().toString(36).substring(2) + Date.now().toString(36);
    sessionStorage.setItem('sessionId', `${random}`);
    setSessionId(`rotoplasmx${random}`);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'cybs-fingerprint';
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=rotoplasmx${random}`;

    const nonScript = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'cybs-fingerprint';
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=rotoplasmx${random}`;

    const noscript = document.createElement('noscript');
    noscript.appendChild(nonScript);

    document.head.appendChild(script);
    document.body.appendChild(noscript);
  }, []);

  return (
    <>
      <FrontasticProvider>
        <Component {...pageProps} />
        <GTMscript />
        <Toaster />
      </FrontasticProvider>
    </>
  );
}

export default appWithTranslation(FrontasticStarter);
